/* eslint-disable react/no-array-index-key */

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { graphql } from 'gatsby';
import { SEO } from '../components/primitives';
import Main from '../components/_privacy/Privacy';

const pageTransition = {
  initial: {},
  enter: {},
  exit: {},
};

const Privacy = ({
  data: {
    site: { siteMetadata: meta },
  },
  location: { pathname },
}) => {
  //

  return (
    <motion.article
      className="relative"
      key={pathname}
      initial="initial"
      animate="enter"
      exit="exit"
      variants={pageTransition}
    >
      <SEO title="Privacy Policy" description={`${meta.siteName}'s Privacy Policy`} />
      <Main />
    </motion.article>
  );
};

export default Privacy;

Privacy.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        siteName
      }
    }
  }
`;
